@import 'variables';

.data-label-pair {
  .data {
    font-weight: bold;
    color: $chr-dark-grey;
  }

  .time, .subheading {
    font-size: 0.75rem; //30px at root font size of 16px
    color: $chr-medium-grey;
  }
}
