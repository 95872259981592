@import "variables";

.error-popover-trigger {
  padding: 0; //autostyle override
  border: 0; //autostyle override
  .error-icon {
    fill: $chr-error-red
  }

}
