@import 'variables';
@import 'media-queries';

.pagination-component.pagination {
  margin: 0;

  .button-container {
    display: inline-block;

    .btn.btn-default,
    .btn.btn-default:focus {
      font-size: 14px;
      font-weight: 600;
      margin: 2px 0;
      border: solid 1px $chr-navisphere-light-grey;
      border-radius: 0;

      &.btn-previous, &.btn-next {
        @include respond-below(sm) {
          background: $chr-active-blue;
          color: $white;
        }
      }
    }

    .btn:not(:disabled).btn-default {
      &:hover {
        color: $chr-active-blue;
        border-color: $chr-navisphere-light-grey;
        background-color: $chr-ultra-light-grey;
      }
    }

    .btn.btn-currentPage {
      background-color: $chr-active-blue;
      color: $white;
      pointer-events: none;
      cursor: default;
    }

    .btn.btn-edge-page {
      display: inline;
    }

    .btn:disabled.btn-default {
      background: transparent;
      font-weight: normal;
      color: $chr-navisphere-light-grey;
      border-color: $chr-navisphere-light-grey;

      &:hover {
        color: $chr-navisphere-light-grey;
      }
    }

    .state-description {
      margin: 0 20px;
    }
  }
}
