@import 'variables';
@import 'media-queries';
@import 'page-styles';

.wide-width-page {
  @extend %page-margin;
  margin-left: $space-xs;
  margin-right: $space-xs;

  @include respond-below(md) {
    margin-left: 12px;
    margin-right: 12px;
  }

  @include respond-below(sm) {
    margin-left: 12px;
    margin-right: 12px;
  }
}

.page-component {
  @extend %page-margin;
  padding: 0;
  margin-bottom: $space-lg;

  @include respond-below(sm) {
    margin-bottom: 24px;
  }
}
