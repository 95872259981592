@import 'variables';

.restricted-banner-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  border: 1px solid #CD0202;
  border-radius: 4px;
  background-color: #FFEFEF;
  margin-bottom: 16px;

  @include respond-below(sm) {
    margin-top: 16px;
  }

  .restricted-area {
    background-color: #CD0202;
    display: flex;
    justify-content: center;
    align-items: center;

    .restricted-logo {
      width: 16px;
      margin: 16px 12px 16px 12px;
      line-height: $font-size-xxl;
    }
  }

  .restricted-banner-text-container {
    width: 100%;
    line-height: $font-size-xxl;

    .restricted-text-area {
      font-size: $font-size-sm;
      font-weight: $bold;
      margin: 16px 16px 0 16px;
      max-width: 1580px;
      color: #111112;
      float: left;
    }

    .bottom-link {
      float: right;
      margin: 16px 16px 16px 0;

      @include respond-below(sm) {
        margin-top: 0px;
      }
    }
  }
}
