@import 'media-queries';

$header-height: 94px;
$header-mobile-height: 94px;
$page-max-width: 1200px;

%page-margin {
  margin-left: $space-xl;
  margin-right: $space-xl;

  @include respond-below(md) {
    margin-left: $space-lg;
    margin-right: $space-lg;
  }

  @include respond-below(sm) {
    margin-left: 12px;
    margin-right: 12px;
  }
}
