@import 'variables';

//Popover styles
.Popover {
  z-index: 9999;

  .Popover-body {
    padding: $space-sm $space-md;
    width: 225px;
    background: white;
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.15);
    color: $carrier-mid-3-grey;
    font-size: 13px;
    border-radius: 3px;
  }

  .Popover-tip {
    fill: white;
  }

  .cap-btn-close {
    background-color: transparent;
    padding-right: 8px;
  }

  .cap-btn-close:focus {
    outline: none;
    box-shadow: none;
  }

  .cap-header {
    padding: 8px;
  }
}

// Override popover color
.disabled-trigger-popover {
  .Popover-body {
    background: $carrier-light-2-grey;
  }

  .Popover-tip {
    fill: $carrier-light-2-grey;
  }
}

//Error Popover Styles
.error-popover-trigger {
  padding: 0;

  svg {
    fill: $chr-error-red;
  }
}

// Span styling needed for two reasons:
// 1) On the mobile view, the popover-trigger is behind the switch and cannot be 'triggered' without pointer-events
// 2) The zero padding keeps the checkboxes lined up vertically
span.popover-trigger {
  .checkbox-switch-container {
    pointer-events: none;
  }

  padding: 0 0 0 0;
}

.Popover-trigger,
.popover-trigger {
  &:focus {
    outline: 0;
  }

  &:active {
    outline: 0;
  }

  padding: 0 0 0 2px; //autostyle override
  border: 0; //autostyle override

  &.info {
    fill: $info-color;
  }

  background-color: transparent;
}