@import 'variables';
@import 'media-queries';

.modal-open .modal {
  display: block;
}

.display-block {
  display: block;
}

.modal-dialog {
  z-index: 1041;
}

.modal-footer.button-bay_mobile-responsive .btn + .btn { //overriding bootstrap style to facilitate custom styles from button-bay_mobile-responsive
  margin-bottom: $space-xs; //same as button-bay_mobile-responsive

  @include respond-below(xxs) {
    margin-bottom: $space-md; //same as button-bay_mobile-responsive
  }
}

#top-close-btn {
  opacity: 1;
}

.modal-backdrop {
  background-color: #000000;
}