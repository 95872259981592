@import 'buttons';

.chr-document-upload, .chr-modal.document-upload {
  .file-upload-container {
    [type="file"] {
      height: 0;
      overflow: hidden;
      width: 0;
    }

    [type="file"] + label {
      @extend %default-button;
      color: $chr-active-blue;
      outline: none;
      position: relative;

      &.required::before {
        content: '';
      }
    }

    [type="file"]:focus + label {
      background-color: #e8f0f4;
      text-decoration: none;
      outline: 1px solid $chr-active-blue;
    }

    margin-top: 1em;
  }

  .grid-view table.data-table thead tr {
    border-bottom: none;
    background-color: none;
  }

  td:first-child {
    width: auto; /* Set a fixed width for the first row */
  }

  td:not(:first-child) {
    width: 25%; /* Allow the other rows to adjust their width */
  }

  @include respond-above(sm) {
    .modal-dialog {
      width: 60%;
      min-width: 700px;
    }

    .accordion-wrapper {
      margin-bottom: 0px;

      .accordion-content {
        padding: 1em 0em;
      }

      .btn-link {
        padding: 0px !important;
      }
    }

    .grid-view {
      margin-bottom: 0px;
      border-radius: 0px;
      box-shadow: none;

      border-bottom: none;
      background-color: none;
    }

    .card-view {
      display: none !important;
    }

    .grid-view.grid-view-with-card {
      display: block !important;
    }
  }

  @include respond-below(sm) {
    .modal-dialog {
      width: 100%;
      margin: 0px;
    }

    .card-view {
      display: block !important;
    }

    .grid-view.grid-view-with-card {
      display: none !important;
    }
  }

  .table-header {
    display: none;
  }

  .table-footer {
    display: none;
  }

  .table-header.mobile-view {
    display: none;
  }

  .bold-text {
    font-weight: bold;
  }
}
