@import 'variables';
@import 'media-queries';

$toast-width: 320px;

.toasts-container {
  .toast-container {
    background: white;
    border: 1px solid $info-color;
    padding: $space-md 24px;
    opacity: 1;
    box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.05);
    width: 320px;

    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;

    &:not(:first-child) {
      margin-top: 12px;
    }

    @keyframes fadein {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    @include respond-below(xxs) {
      width: 87vw;
    }

    .button-bay {
      text-align: right;

      button, a {
        border: 0;
        padding-left: 15px;
        font-weight: normal;
      }
    }
  }
}
