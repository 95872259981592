@import "variables";
@import "buttons";
@import 'page-styles';

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.controlled-accordion.accordion-wrapper {
  box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.05);
  border: 1px solid rgb(173, 173, 173);
  border-radius: 6px;
  overflow: hidden;
  max-width: $page-max-width;
  width: -webkit-fill-available;
  height: fit-content;

  > .accordion-header {
    cursor: pointer;
    padding: 10px 10px 10px 15px;
    margin-bottom: 0;

    .accordion-header-button-bay {
      font-size: $font-size-sm;
      text-align: right;

      .mobile-modify-search-button {
        margin-top: 12px;
      }
    }

    .ns-icon {
      font-size: 0.625rem;
      margin-top: 8px;
      float: left;
    }

    .ns-expand {
      display: none;
    }

    .ns-next {
      display: inline-block;
    }
  }

  .search-heading {
    font-size: $font-size-md;
    display: block;

    .search-config {
      padding-right: 12px;
    }

    .heading-title {
      font-weight: bold;
    }
  }

  > .accordion-content {
    padding: 1em 2.2em;
    display: none;
  }
}

.controlled-accordion.accordion-wrapper.is-expanded {
  overflow: visible;

  > .accordion-header {
    .ns-expand {
      display: inline-block;
    }

    .ns-next {
      display: none;
    }

    .accordion-header-button-bay {
      display: none;
    }
  }

  > .accordion-content {
    display: block;
    -webkit-animation: fadeIn 0.5s;
    animation: fadeIn 0.5s;
  }
}

.search-footer {
  display: block;

  button, a {
    margin-bottom: $space-xs; //providing small amount of padding below incase they wrap before 375px breakpoint
    @extend %mobile-button-styling;
  }

  button:not(:first-child), a:not(:first-child) {
    margin-left: $space-sm;
  }

  @include respond-below(xxs) {
    button, a {
      margin-bottom: $space-md;

      &:last-child {
        margin-bottom: 0;
      }
    }

    button:not(:first-child), a:not(:first-child) {
      margin-left: 0;
    }
  }
}
