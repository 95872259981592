@import 'media-queries';

.toasts-container {
  position: fixed;
  z-index: 3000;
  right: 20px;
  overflow-y: auto;

  @include respond-below(xxs) {
    left: auto;
    right: auto;
    max-height: unset;
    overflow-y: unset;
  }
}
